import React, { useState, useEffect, useRef } from 'react';

import { fetchContactInvoices, createCardTransactionRequest } from '../api/index.ts';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

import styles from './mainStyles';
import WithMenu from '../Components/WithMenu'
import InvoiceCard from '../Components/InvoiceCard'
import WithUserLoggedIn from '../lib/WithUserLoggedIn'
import Spinner from '../Components/Spinner/index';
import DefaultNumberFormat from '../utils/DefaultNumberFormat';

async function getInvoices(userId, setInvoicesList, invoicesStatus, setRequestStatus) {
	setRequestStatus('pending');
	try {
		const response = await fetchContactInvoices(userId);
		response.data.sort((a, b) => new Date(a.date) > new Date(b.date) ? 1 : -1);
		setInvoicesList(response.data);
		invoicesStatus.current = response.data.map(({ date, status: { name }, subscriptionId }) => ({
			date,
			subscriptionId,
			status: name
		}));
		setRequestStatus('ready');
	} catch (error) {
		console.log(error);
	}
}

function Invoices({ classes, history }) {
	const [ requestStatus, setRequestStatus ] = useState('pending');
	const [ invoicesList, setInvoicesList ] = useState([]);
	const invoicesStatus = useRef([]);

	// Debe haber una forma de sacar userLoggedIn del componente WithUserLoggedIn
	const userLoggedIn = JSON.parse(localStorage.getItem('userLoggedIn'));

	useEffect(() => {
		getInvoices(userLoggedIn.id, setInvoicesList, invoicesStatus, setRequestStatus);
	}, [ userLoggedIn.id ]);

	async function goToCheckout(invoiceId) {
		try {
			const response = await createCardTransactionRequest(invoiceId);
			const { checkoutLink } = response.data;
			window.open(checkoutLink, '_blank')
		} catch (error) {
			console.log(error);
		}
	}
	function logout() {
		localStorage.clear();
		history.push('/login');
	}
	return (
		<>
			<WithUserLoggedIn
				history={history}
			>
				<WithMenu
					history={history}
					title="Facturas"
					userLoggedIn={userLoggedIn}
					logout={logout}
				>

					{ requestStatus === 'pending' ? (
						<div
							style={{
								marginTop: '6rem'
							}}
						>
							<Spinner />
						</div>
					) : !invoicesList.length ? (
						<Card
							style={{
								textAlign: 'center',
								width: '50rem',
								margin: '0 auto',
								background: '#f2f2f2',
								color: '#333'
							}}
						>
							<CardContent>
								No tienes ninguna factura aún
							</CardContent>
						</Card>
					) : (
						<>
							{Boolean(userLoggedIn?.availableAmount) && (
								<Card
									style={{
										height: '5rem',
										minWidth: '20rem',
										width: 'max-content',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										marginBlockEnd: '2rem'
									}}
								>
									<Typography
										variant="h5"
									>
										Saldo a favor:
										&nbsp;
										<DefaultNumberFormat
											style={{ color: 'green' }}
											value={userLoggedIn?.availableAmount}
										/>
									</Typography>
								</Card>
							)}
							<Grid
								container
							>
								{invoicesList.map(invoice => (
									<InvoiceCard
										key={invoice.id}
										invoicesStatus={invoicesStatus}
										invoice={invoice}
										goToCheckout={goToCheckout}
									/>
								))}
							</Grid>
							<div
								className={classes.inputContainer}
								style={{ marginTop: '3rem' }}
							>
								<Button
									variant="contained"
									className={classes.button}
									onClick={() => getInvoices(userLoggedIn.id, setInvoicesList, invoicesStatus, setRequestStatus)}
								>
									Refrescar
								</Button>
							</div>
						</>
					)}
				</WithMenu>
			</WithUserLoggedIn>
		</>
	);
}

export default withStyles(styles)(Invoices);