import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import { Dialog, DialogContentText, DialogActions } from '@material-ui/core';
import DefaultNumberFormat from '../utils/DefaultNumberFormat';

const useStyles = makeStyles({
	root: {
		minWidth: 275,
		width: '100%',
		borderRadius: 18,
		margin: '2%',
		borderLeft: '50px solid #FE6730',
		flexGrow: 1
	},
	flex: {
		display: 'flex'
	},
	title: {
		fontSize: 14
	},
	pos: {
		marginBottom: 12,
		fontWeight: 'bold',
		fontSize: 20
	},
	cover: {
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		height: '24vh'
	},
	center: {
		justifyContent: 'center'
	},
	inputContainer: {
		marginBottom: '3%',
		width: 200
	},
	button: {
		background: `linear-gradient(to right, #feb940, #f4085f)`,
		width: '100%',
		borderRadius: '15px',
		height: 40,
		marginTop: '10%',
		color: 'white',
		fontFamily: 'Noto Sans',
		fontSize: 18,
		fontWeight: 700,
		textTransform: 'none',
		display: 'inline-block'
	},
	end: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	modal: {
		padding: '4rem 1rem 2rem',
		width: '40rem'
	},
	dialogActions: {
		display: 'flex',
		justifyContent: 'space-around',
		marginTop: '2rem',
		'& button': {
			color: 'white',
			fontFamily: 'Noto Sans',
			fontSize: 16,
			textTransform: 'none',
			width: '20rem',
			borderRadius: '1rem',
			background: `#1b2435 !important`,
			'&:hover': {
				filter: 'brightness(1.5)'

			}
		}
	}
});

export default function SimpleCard({ invoice, invoicesStatus, goToCheckout }) {
	const classes = useStyles();
	const [ { modalOpened, modalContent, modalButtons }, setModal ] = useState({
		modalOpened: false,
		modalContent: '',
		modalButtons: []
	});

	const formatIsoToFriendlyDate = date => {
		if (date) {
			return date.split('-').reverse().join('/')
		}
		return date
	}

	const handleAmountToShow = () => {
		const today = new Date(new Date().toISOString().split('T')[0]);
		const firstDueDate = new Date(invoice.actualDueDates.updatedFirstDueDate);
		const amountToShow = invoice.status.name === 'Paid'
			? invoice.amountPaid
			: today <= firstDueDate
				? invoice.actualDueDates.firstAmount
				: invoice.actualDueDates.secondAmount;
		return amountToShow;
	}

	const handleInvoicesInOrder = (currentInvoicesDate, currentSubscriptionId, next) => {
		if (!currentSubscriptionId) return next();
		const notTheInvoiceToPay = invoicesStatus.current.some(({ status, date, subscriptionId }) => {
			return (status === 'Pending' && subscriptionId === currentSubscriptionId) && new Date(date) < new Date(currentInvoicesDate);
		});
		if (notTheInvoiceToPay) return setModal({
			modalOpened: true,
			modalContent: (
				<Typography variant="h5" align="center">
					Tienes facturas anteriores sin pagar,
					<br />
					asegúrate de cancelar esas primero.
				</Typography>
			),
			modalButtons: [
				{
					label: 'Ok, pagaré las otras primero',
					handler: () => setModal({
						modalOpened: false,
						modalContent: '',
						modalButtons: []
					})
				},
				{
					label: 'Lo sé, quiero pagar igual',
					handler: next
				}
			]
		});
		else next();
	};

	return (
		<Grid
			container
			item
			xs={12}
			sm={6}
		>
			<Card
				className={classes.root}
			>
				<div
					style={{ margin: '3%' }}
				>
					<Grid
						container
						className={classes.flex}
					>
						<Grid
							item
							xs={6}
						>
							<Typography
								className={classes.title}
							>
								{`Fecha de emisión: ${formatIsoToFriendlyDate(invoice.date)}`}
							</Typography>
						</Grid>
						<Grid
							item
							xs={6}
							className={classes.end}
						>
							<Typography variant="h5" component="h2">
								<DefaultNumberFormat
									value={handleAmountToShow()}
								/>
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							className={classes.end}
						>
							<Typography
								style={{ color: invoice.status.name === 'Paid' ? '#7CB77C' : '#FFA500' }}
								className={classes.pos}
							>
								{`${invoice.collectType === 'CHARGE' ? 'PAGO AUTOMÁTICO - ' : ''}${invoice.status.name === 'Paid' ? 'Pagada' : 'Pendiente'}`}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						alignItems="center"
					>
						<Grid
							item
							xs={6}
						>
							<Typography
								className={classes.pos}
								color="textSecondary"
							>
								{invoice.description}
							</Typography>
							<Typography
								variant="body2"
								component="p"
							>
								<div>
									<p>
										&nbsp;
										Boleta N°
										&nbsp;
										<strong>{invoice.id}</strong>
										&nbsp;
									</p>
								</div>
							</Typography>
						</Grid>
						<Grid
							item
							xs={6}
						>
							<CardMedia
								className={classes.cover}
								image={invoice.organization.logo}
								title="Logo"
							/>
						</Grid>
					</Grid>
					<Grid
						container
						className={classes.center}
					>
						{invoice.status.name !== 'Paid' && (
							<>
								<Grid
									item
									xs={12}
									sm={6}
								>
									<div
										className={classes.inputContainer}
									>
										<Button
											onClick={() => handleInvoicesInOrder(invoice.date, invoice.subscriptionId, () => window.open(invoice.renderUrl, '_blank'))}
											className={classes.button}
											style={{ display: 'flex', justifyContent: 'center' }}
										>
											Descargar Cupón
										</Button>
									</div>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
								>
									<div
										className={classes.inputContainer}
									>
										<Button
											onClick={() => handleInvoicesInOrder(invoice.date, invoice.subscriptionId, () => goToCheckout(invoice.id))}
											className={classes.button}
										>
											Pagar con tarjeta
										</Button>
									</div>
								</Grid>
							</>
						)}
					</Grid>
				</div>
			</Card>
			<Dialog
				open={modalOpened}
				classes={{
					paper: classes.modal
				}}
			>
				<DialogContentText>
					{modalContent}
				</DialogContentText>
				<DialogActions
					classes={{
						root: classes.dialogActions
					}}
				>
					{modalButtons.map(button => (
						<Button
							onClick={button.handler}
						>
							{button.label}
						</Button>
					))}
				</DialogActions>
			</Dialog>
		</Grid>
	);
}